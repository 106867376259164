import { Breadcrumb, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classes from "./News.module.scss";
import clsx from "clsx";

const Organograma: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            navigate("/noticias");
          }}
        >
          Início
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Organograma</Breadcrumb.Item>
      </Breadcrumb>

      <div className={clsx(classes.introduction, "col-12")}>
        <p>Organograma</p>
      </div>

      <img width="100%" src="/media/news/organogramas_v0509.png" />
    </Container>
  );
};

export default Organograma;
