import { X } from "@phosphor-icons/react";
import classes from "./Modal.module.css";
import { Modal } from "react-bootstrap";

type Props = {
  isOpen: boolean;
  handleClose: any;
  title?: any;
  subtitle?: any;
  width?: string;
  height?: string;
  maxHeight?: string;
  footer?: JSX.Element;
  style?: any;
};

const CustomModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  children,
  width,
  height,
  maxHeight,
  footer,
  style,
}) => {
  return (
    <Modal style={style} show={isOpen} onHide={handleClose}>
      <Modal.Header className={classes.modal_header}>
        <div className="col-12 d-flex justify-content-between">
          <span style={{ width: "22px", marginRight: "1.25rem" }}></span>
          <span className={classes.modal_title}>{title}</span>
          <span className={classes.modal_close}>
            <X onClick={() => handleClose()} size={22} />
          </span>
        </div>
        <div>
          {subtitle ? (
            <>
              {typeof subtitle == "string" ? (
                <h5 className={classes.modal_subtitle}>{subtitle}</h5>
              ) : (
                <div className={classes.modal_subtitle}>{subtitle}</div>
              )}{" "}
            </>
          ) : null}
        </div>
      </Modal.Header>
      <Modal.Body
        className={classes.modal_body}
        style={{
          maxHeight: maxHeight || "",
          height: height || "",
          width: width || "",
          overflow: style?.overflow || "",
        }}
      >
        {children}
      </Modal.Body>
      {footer ? (
        <Modal.Footer className={classes.modal_footer}>{footer}</Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default CustomModal;
