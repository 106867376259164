import "react-js-cron/dist/styles.css";
import Button from "../../UI/Button/Button";
import CustomModal from "../../UI/Modal/Modal";

type Props = {
  isOpen: any;
  onConfirm?: any;
  onCancel?: any;
};

const ModalConfirmation: React.FC<Props> = ({
  isOpen,
  onConfirm,
  onCancel,
}) => {
  return (
    <CustomModal
      title={"Atenção!"}
      isOpen={isOpen}
      width="500px"
      handleClose={() => {}}
    >
      <div className="d-flex flex-column align-items-center">
        <h4 className="text-center">
          Tem a certeza que pretende abandonar a página?
        </h4>
        <div className="d-flex gap-3">
          <Button
            style="btn btn-secondary"
            onClick={onConfirm}
            title={"Continuar na  página"}
          ></Button>
          <Button
            style="btn btn-secondary-danger"
            onClick={onCancel}
            title={"Abandonar a página"}
          ></Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalConfirmation;