import ReactDOM from "react-dom/client";
import App from "./App";
import Store from "./setup/store/index";
import { BrowserRouter, Outlet } from "react-router-dom";
import { MasterInit } from "./components/_metronic/layout/MasterInit";
import Toast from "./components/UI/Toast/Toast";

//PROVIDERS
import { Provider } from "react-redux";
import { LayoutProvider } from "./components/_metronic/layout/core";
import { I18nProvider } from "./components/_metronic/i18n/i18nProvider";
import { MetronicI18nProvider } from "./components/_metronic/i18n/Metronici18n";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import "./components/_metronic/assets/sass/style.scss";
import "./components/_metronic/assets/sass/style.react.scss";
import "react-toastify/dist/ReactToastify.css";

//CSS
import "./index.css";
import "./assets/modifiers.css";
import "./assets/layout.scss";
import "./assets/toasts.css";
import { BlockProvider } from "./components/_metronic/layout/BlockContext";
import { BlockEventsProvider } from "./components/_metronic/layout/BlockEventsContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MetronicI18nProvider>
    <I18nProvider>
      <LayoutProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={Store}>
            <Outlet />
            <MasterInit />
            <Toast />
            <BrowserRouter>
              <BlockProvider>
                <BlockEventsProvider>
                  <App />
                </BlockEventsProvider>
              </BlockProvider>
            </BrowserRouter>
          </Provider>
        </QueryClientProvider>
      </LayoutProvider>
    </I18nProvider>
  </MetronicI18nProvider>
);
