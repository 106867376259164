import React, { createContext, useState, ReactNode, useEffect } from "react";

interface MyContextProps {
  blocked?: boolean;
  setBlocked: (blocked: boolean) => void;
}

const BlockEventsContext = createContext<MyContextProps | undefined>(undefined);

export const BlockEventsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [blocked, setBlocked] = useState<boolean>(false);

  const updateBlockEvents = (blocked: boolean) => {
    setBlocked(blocked);
  };

  return (
    <BlockEventsContext.Provider
      value={{
        blocked,
        setBlocked,
      }}
    >
      <div
        style={blocked ? { pointerEvents: "none", opacity:"0.5" } : { pointerEvents: "auto" }}
      >
        {children}
      </div>
    </BlockEventsContext.Provider>
  );
};

export default BlockEventsContext;
