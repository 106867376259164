/*Para usar o blockprovider ao abandonar uma página desejada, é necessário colocar na página que pretende o seguinte:
import BlockContext from "../../../components/_metronic/layout/BlockContext" (Caminho até ao BlockContext);
const context = useContext(BlockContext) -> Associar o context a uma constante;
const { pathname } = useLocation();

Colocar na função ou evento que pretende bloquear a página:
  context?.updateNavigationClick(pathname, pathname);
  if (pathname != context?.navigationClick) {
    context?.updateBlockNavigation(true);
  }

Atualizar o updateBlockNavigation para false sempre que a página for desmontada
  useEffect(() => {
    return () => {
      clearDetTrab(token).then(() => {
        context?.updateBlockNavigation(false);
      });
    };
  }, []);
*/

import React, { createContext, useState, ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import ModalConfirmation from "./ModalConfirmation";
import { useLocation, useNavigate } from "react-router-dom";

interface MyContextProps {
  value: string;
  setValue: (value: string) => void;
  blockedNavigation: boolean;
  updateBlockNavigation: (blocked: boolean) => void;
  navigationClick: string | undefined;
  updateNavigationClick: (path: string, location: any) => void;
  modalOpen: boolean;
  updateModalState: (open: boolean) => void;
}

const BlockContext = createContext<MyContextProps | undefined>(undefined);

export const BlockProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [value, setValue] = useState<string>("initial value");
  const [blockedNavigation, setBlockedNavigation] = useState<boolean>(false);
  const [navigationClick, setNavigationClick] = useState<string>();
  const [actualLocation, setActualLocation] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const updateBlockNavigation = (blocked: boolean) => {
    setBlockedNavigation(blocked);
  };
  const updateNavigationClick = (path: string, location: any) => {
    setNavigationClick(path);
    setActualLocation(location);
  };
  const updateModalState = (open: boolean) => {
    setModalOpen(open);
  };

  useEffect(() => {
    if (
      navigationClick &&
      actualLocation != navigationClick &&
      blockedNavigation
    ) {
      setModalOpen(true);
    }
  }, [navigationClick]);

  return (
    <BlockContext.Provider
      value={{
        value,
        setValue,
        blockedNavigation,
        updateBlockNavigation,
        navigationClick,
        updateNavigationClick,
        modalOpen,
        updateModalState,
      }}
    >
      {ReactDOM.createPortal(
        <ModalConfirmation
          isOpen={modalOpen}
          onConfirm={() => {
            setModalOpen(false);
            updateNavigationClick(actualLocation || "/", actualLocation);
          }}
          onCancel={() => {
            setModalOpen(false);
            navigate(navigationClick || "/");
          }}
        />,
        document.getElementById("root-modals")!
      )}
      {children}
    </BlockContext.Provider>
  );
};

export default BlockContext;
