import clsx from "clsx";
import { X } from "@phosphor-icons/react";
import { useMemo, useEffect, useState } from "react";
import { GetIcon } from "../../../setup/utilities/icons";

type Props = {
  id?: any;
  title?: string;
  placeholder?: string;
  type: string;
  state: any;
  stateUpdate: any;
  param?: any;
  space?: any;
  order?: any;
  pattern?: any;
  size?: any;
  icon?: string;
  required?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  variant?: string;
  min?: any;
  max?: any;
  maxLength?: any;
  invalidValue?: boolean;
  invalidValueColor?: string;
  step?: number;
  alignCenter?: boolean;
  moddifiers?: string;
  onClick?: any;
  minWidth?: string;
  maxWidth?: string;
};

const Input: React.FC<Props> = ({
  id,
  title,
  placeholder,
  type,
  required,
  state,
  stateUpdate,
  param,
  space,
  disabled,
  pattern,
  size,
  icon,
  clearable,
  variant,
  min,
  max,
  maxLength,
  invalidValue,
  invalidValueColor,
  step,
  alignCenter,
  moddifiers,
  onClick,
  minWidth,
  maxWidth,
}) => {
  const key = useMemo(() => {
    switch (type) {
      case "file": {
        return "files";
      }
      default:
        return "value";
    }
  }, [type]);

  const JSXIcon = icon ? GetIcon(icon) || null : null;

  return (
    <div
      className={clsx(space !== undefined ? space : "mb-2", size && size)}
      onClick={onClick ? onClick : () => {}}
      style={
        minWidth && maxWidth
          ? { minWidth: minWidth, maxWidth: maxWidth }
          : minWidth
          ? { minWidth: maxWidth }
          : maxWidth
          ? { maxWidth: maxWidth }
          : {}
      }
    >
      <div
        className={clsx(
          moddifiers && moddifiers,
          "form-input",
          variant && `form-input-${variant}`,
          disabled && "disabled"
        )}
        style={
          invalidValue
            ? {
                border: invalidValueColor
                  ? `2px solid ${invalidValueColor}`
                  : "2px solid red",
              }
            : {}
        }
      >
        {title ? (
          <label htmlFor={id} className={clsx(required && "required")}>
            {title}
          </label>
        ) : null}

        <div>
          {JSXIcon ? <JSXIcon size={18} weight="bold" /> : null}

          <input
            id={id}
            type={type}
            required={required ? required : false}
            value={state || ""}
            placeholder={placeholder || ""}
            onChange={(e) => {
              if (param != null) {
                stateUpdate(e.target[key], param);
              } else {
                stateUpdate(e.target[key]);
              }
            }}
            style={type == "color" ? { height: "20px" } : {}}
            step={type == "number" && step ? step : 0}
            disabled={disabled}
            pattern={pattern}
            className={clsx(icon && "ml-1", alignCenter && "text-center")}
            min={min}
            max={max}
            maxLength={maxLength}
            disabled-dates="2024-01-01, 2024-07-04, 2024-12-25"
          />

          {clearable && state ? (
            <div className="clear">
              <X
                size={14}
                weight="bold"
                onClick={() => {
                  stateUpdate("");
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Input;
